<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('APMA.assignTask')}`"
      :backButton="true"
      @backToPage="moveToOther('APMA')"
    >
      <template #form>
        <form>
          <div :class="!isMobile ? 'i-flex-parent' : ''">
            <div :class="`${!isMobile ? 'i-flex-child' : ''}`">
              <Input
                :label="$t('APMA.startDate')"
                type="date"
                v-model="edited_item.start_date"
                class="s-mb-20"
                :error="validation.start_date"
              />
              <Input
                :label="$t('APMA.endDate')"
                type="date"
                v-model="edited_item.end_date"
                class="s-mb-20"
                :error="validation.end_date"
              />
              <div class="s-mb-20">
                <label class="s-input-label">{{ $t("APMA.cyclePhase") }}</label>
                <Multiselect
                  v-model="edited_item.cycle_phase"
                  :placeholder="`${$t('general.searchOrAdd')} ${$t(
                    'APMA.cyclePhase'
                  )}`"
                  label="name"
                  :options="cyclephase_options.map((type) => type.id)"
                  :custom-label="
                    (opt) => cyclephase_options.find((x) => x.id == opt).name
                  "
                ></Multiselect>
                <span class="s-input-error" v-if="validation.cycle_phase">
                  {{ $t("general.fillThisField") }}
                </span>
              </div>
              <div class="s-mb-20">
                <label class="s-input-label">{{ $t("APMA.priority") }}</label>
                <Multiselect
                  v-model="edited_item.priority"
                  :placeholder="`${$t('general.searchOrAdd')} ${$t(
                    'APMA.priority'
                  )}`"
                  label="name"
                  :options="priority_options.map((type) => type.id)"
                  :custom-label="
                    (opt) => priority_options.find((x) => x.id == opt).name
                  "
                ></Multiselect>
                <span class="s-input-error" v-if="validation.priority">
                  {{ $t("general.fillThisField") }}
                </span>
              </div>
            </div>
          </div>
          <Textarea
            v-model="edited_item.measure"
            :label="$t('APMA.measure')"
            class="s-mb-20"
            :error="validation.measure"
          />
          <Textarea
            v-model="edited_item.detail"
            label="Detail"
            class="s-mb-20"
            :error="validation.detail"
          />

          <div class="s-mb-20">
            <label class="s-input-label">{{
              $t("APMA.stakeholderAgencies")
            }}</label>
            <Multiselect
              v-model="edited_item.stakeholders_agencies"
              :tag-placeholder="`${$t('general.addThisNew')} ${$t(
                'sidebar.stakeholders'
              )}`"
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.stakeholders'
              )}`"
              label="name"
              :options="stakeholders_options.map((type) => type.id)"
              :custom-label="
                (opt) => stakeholders_options.find((x) => x.id == opt).name
              "
              :multiple="true"
              @input="onChangeStakeholder"
            ></Multiselect>
            <span class="s-input-error" v-if="validation.stakeholders_agencies">
              {{ $t("general.fillThisField") }}
            </span>
          </div>
          <div class="s-mb-20">
            <label class="s-input-label"> {{ $t("APMA.leads") }}</label>
            <Multiselect
              v-model="edited_item.leads"
              tag-placeholder=""
              :placeholder="`${$t('general.searchOrAdd')} ${$t('APMA.leads')}`"
              label="name"
              :options="leads_options.map((type) => type.id)"
              :custom-label="
                (opt) => leads_options.find((x) => x.id == opt).name
              "
              :multiple="true"
            ></Multiselect>
            <span class="s-input-error" v-if="validation.leads">
              {{ $t("general.fillThisField") }}
            </span>
          </div>

          <Textarea
            v-model="edited_item.expected_result"
            :label="$t('APMA.expectedResult')"
            class="s-mb-20"
            :error="validation.expected_result"
          />
          <Input
            v-model="edited_item.expected_timeline"
            :label="$t('APMA.expectedTimeline')"
            class="s-mb-20"
            :error="validation.expected_timeline"
            :placeholder="$t('APMA.monthYear')"
          />
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button
              class="s-ml-auto"
              color="error"
              @click="moveToOther('APMA')"
            >
              {{ $t("general.cancel") }}
            </Button>
            <Button class="s-ml-20" @click="onFinish" :isLoading="isLoading">
              {{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import Multiselect from "vue-multiselect";
import form from "@/mixins/form";
import common from "@/mixins/common";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate, Multiselect },
  mixins: [form],
  props: {
    menuName: String,
    // item: Object,
    inputType: String,
  },
  data() {
    return {
      edited_item: {
        cycle_phase: "",
        start_date: "",
        end_date: "",
        priority: "",
        measure: "",
        detail: "",
        stakeholders_agencies: [],
        leads: [],
        expected_result: "",
        expected_timeline: "",
      },

      validation: {
        cycle_phase: false,
        start_date: false,
        end_date: false,
        priority: false,
        measure: false,
        detail: false,
        stakeholders_agencies: false,
        leads: false,
        expected_result: false,
        expected_timeline: false,
      },
      leads_options: [],
      isLoading: false,
    };
  },

  mounted() {
    this.initData();
    this.getEditedData();
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      item: "actionplan/getActionPlan",
      rows: "actionplan/getFiles",
      options: "expertise/getExpertise",
      cyclephase_options: "cyclephase/getCyclePhase",
      priority_options: "priority/getPriority",
      stakeholders_options: "stakeholder/getStakeholder",
    }),
  },
  methods: {
    ...mapActions({
      setModal: "setModal",
      setActionPlan: "actionplan/setActionPlanSingleData",
      setExpertise: "expertise/setExpertiseDropdownData",
      setCyclePhase: "cyclephase/setCyclePhaseData",
      setStakeholder: "stakeholder/setStakeholderDropdown",
      setPriority: "priority/setPriorityData",
      setFiles: "actionplan/setFiles",
      onDownloadFile: "actionplan/downloadFileAction",
      onSubmitActionPlan: "actionplan/postActionPlanData",
      onSubmitEditActionPlan: "actionplan/putActionPlanData",
    }),
    async initData() {
      this.setLoadingPage(true)
      if (this.inputType === "Detail") {
        await this.setActionPlan(this.$route.params.id);
      }
      await this.setExpertise();
      await this.setCyclePhase();
      await this.setPriority();
      await this.setStakeholder();
      await this.setFiles(this.$route.params.id);
      // this.edited_item.cycle_phase = this.item.cycle_phase_name;
      // this.edited_item.priority = this.item.priority_name;
      // this.edited_item.measure = this.item.measure;
      // this.edited_item.detail = this.item.detail;
      // this.edited_item.expected_result = this.item.expected_result;
      // this.edited_item.expected_timeline = this.item.expected_timeline;
      // this.edited_item.result = this.item.result;
      // this.edited_item.leads = this.item.leads;
      // this.edited_item.stakeholders_agencies = this.item.stakeholders;
      // this.file_id = this.rows.file_id;
       this.setLoadingPage(false)
    },
    async getEditedData() {
      if (this.inputType == "Edit") {
        await this.setActionPlan(this.$route.params.id);
        this.edited_item.start_date = this.item.start_date;
        this.edited_item.end_date = this.item.end_date;
        this.edited_item.measure = this.item.measure;
        this.edited_item.detail = this.item.detail;
        this.edited_item.expected_result = this.item.expected_result;
        this.edited_item.expected_timeline = this.item.expected_timeline;
        let stakeholder_temp = new Array();
        this.item.stakeholders.forEach((element) => {
          stakeholder_temp.push(element.id);
        });

        this.edited_item.stakeholders_agencies = Object.assign(
          [],
          stakeholder_temp
        );

        this.leads_options = this.item.stakeholders;
        let leads_temp = new Array();
        this.item.leads.forEach((element) => {
          leads_temp.push(element.id);
        });

        this.edited_item.leads = Object.assign([], leads_temp);
        this.edited_item.cycle_phase = this.item.cycle_phase_id;
        this.edited_item.priority = this.item.priority_id;
      }
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        false,
        null,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        var data = {
          measure: this.edited_item.measure,
          detail: this.edited_item.detail,
          expected_result: this.edited_item.expected_result,
          expected_timeline: this.edited_item.expected_timeline,
          start_date: this.edited_item.start_date,
          end_date: this.edited_item.end_date,
          cycle_phase_id: this.edited_item.cycle_phase,
          priority_id: this.edited_item.priority,
          stakeholder_array: this.edited_item.stakeholders_agencies,
          lead_array: this.edited_item.leads,
        };
        if (this.inputType == "New") {
          await this.onSubmitActionPlan(data);
        } else {
          data = {
            ...data,
            id: this.$route.params.id,
          };
          await this.onSubmitEditActionPlan(data);
        }

        this.$router.push(this.$translate({ name: "APMA" }));
      }
      this.isLoading = false;
    },

    onChangeStakeholder(id) {
      this.leads_options = this.stakeholders_options.filter((x) =>
        id.includes(x.id)
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
